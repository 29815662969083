import React from "react"
import Zone from "../../components/zone";

export default function GameAbout() {
  return (
    <Zone title="Inti Voxel Games" color="#ff852c">
      I make games
    </Zone>
  )
}
